import ApolloClient, { ApolloQueryResult } from "apollo-boost";
import { gql } from "apollo-boost";
import { Data, MenuItem } from "../bemodocs-graphql-response.json";
import { appConfig } from "../App";

const cmsServerUri = process.env.REACT_APP_CMS_SERVER_URI;
function DataAdapter(dataSource: Data): Data {
  dataSource.menu[0].menuItemsList.menuItems.map((menuItem) => {
    adaptSourceDocUri(menuItem);
  });
  return dataSource;
}
function adaptSourceDocUri(menuItem: MenuItem) {
  //use file content url when it available
  if (menuItem.bemoFileContent?.urls[0])
    menuItem.bemoMarkdownSourceUri.url = menuItem.bemoFileContent?.urls[0];

  //use configured sourceFile host for doc source relativ url
  if (menuItem.bemoMarkdownSourceUri.url.startsWith("/"))
    menuItem.bemoMarkdownSourceUri.url =
      appConfig().SourceFileHost + menuItem.bemoMarkdownSourceUri.url;
  menuItem.menuItemsList?.menuItems.map((submenu) =>
    adaptSourceDocUri(submenu)
  );
}

const CMS_BEMODOCS_QUERY = gql`
  query BemoDocsMenu {
    menu {
      menuItemsList {
        menuItems {
          ... on LinkMenuItem {
            bemoFileContent {
              paths
              urls
            }
            bemoMarkdownPageId
            bemoMarkdownContribUri {
              text
              url
            }
            bemoMarkdownSourceUri {
              text
              url
            }
            linkMenuItem {
              name
              url
            }
          }
          menuItemsList {
            menuItems {
              ... on LinkMenuItem {
                bemoFileContent {
                  paths
                  urls
                }
                bemoMarkdownPageId
                bemoMarkdownContribUri {
                  text
                  url
                }
                bemoMarkdownSourceUri {
                  text
                  url
                }
                linkMenuItem {
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
export class CmsClient {
  client: ApolloClient<any>;
  constructor() {
    this.client = new ApolloClient({
      uri: appConfig()?.CmsServerUri,
    });
  }
  async GetDocumentationGraph(): Promise<Data> {
    let result = await this.client.query<Data>({
      query: CMS_BEMODOCS_QUERY,
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    });
    return DataAdapter(result.data);
  }
}
