import axios, { AxiosPromise } from "axios";

export class MarkdownClient {
  localStorageSupported: boolean;

  constructor() {
    //RFU caching
    this.localStorageSupported =
      typeof window["localStorage"] != "undefined" &&
      window["localStorage"] != null;
  }
  get(mdFileUri: string): AxiosPromise<any> {
    return axios(mdFileUri);
  }
}
