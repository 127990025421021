import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { MarkdownClient } from "../services/markdownClient";
import "./MarkdownViewver.css";
import { SectionLoader } from "./SectionLoader";
import { useNavigationContext } from "../context/navigationContext";
import { appConfig } from "../App";

type stateType = {
  title: string;
  content: string;
};
type propsType = {
  fileTitle: string;
  fileUri: string;
};
export const MarkdownViewver = (props: propsType) => {
  const [state, setState] = useState({ content: "" } as stateType);
  const [loaded, setLoaded] = useState(false);
  const [navState, updateNav] = useNavigationContext("navigation");
  const transformImageUri = (input: string) =>
    /^https?:\/\//.test(input)
      ? input
      : `${appConfig().SourceFileHost}/${input}`;
  //no url host transformation for relativ page links (without extension) or absolute page links
  const transformLinkUri = (input: string) =>
    /^https?:\/\//.test(input) || !/^.*\.([\w]*)/.test(input)
      ? input
      : `${appConfig().SourceFileHost}/${input}`;

  useEffect(() => {
    (async function anyNameFunction() {
      const mdClient = new MarkdownClient();
      console.log("fileUri", props.fileUri);
      const result = await mdClient.get(props.fileUri);
      setState({ content: result.data, title: props.fileTitle });
      setLoaded(true);
      updateNav({ currentPage: props.fileTitle });
    })();
  }, [props.fileUri]);
  return loaded ? (
    <article className="markdown-body">
      <h1>{state.title}</h1>
      <ReactMarkdown
        source={state.content}
        transformLinkUri={transformLinkUri}
        transformImageUri={transformImageUri}
      />
    </article>
  ) : (
    <SectionLoader />
  );
};
