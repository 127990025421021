import React from "react";
import { MenuItem } from "../bemodocs-graphql-response.json";
import { useCmsDataContext } from "../context/cmsDataContext";
import { Switch, Route } from "react-router-dom";
import { MarkdownViewver } from "./MarkdownViewver";

const flatenMenu = (menuList: MenuItem[]) =>
  menuList
    .map((menu) =>
      menu.menuItemsList?.menuItems
        ? [menu].concat(menu.menuItemsList?.menuItems.map((m) => m))
        : [menu]
    )
    .reduce((a, b) => a.concat(b));

export function BuildCmsRoutes() {
  const [cmsStore] = useCmsDataContext("menuItemList");
  //first element should be default/home md page
  const homeMenu = cmsStore[0];
  //flating two level menu to build proper Route definition
  const flatMenu = flatenMenu(cmsStore);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <MarkdownViewver
            fileTitle={homeMenu?.linkMenuItem.name ?? ""}
            fileUri={homeMenu?.bemoMarkdownSourceUri?.url ?? ""}
          />
        )}
      />
      {flatMenu.map((menuItem, i) => (
        <Route
          exact
          key={i}
          path={menuItem.linkMenuItem.url}
          render={() => (
            <MarkdownViewver
              fileTitle={menuItem?.linkMenuItem.name ?? ""}
              fileUri={menuItem?.bemoMarkdownSourceUri?.url ?? ""}
            />
          )}
        />
      ))}
    </Switch>
  );
}
