import React from "react";
import "./AppHeader.css";
import logo from "../logo.svg";
import { Button } from "reactstrap";
import { HamburgerMenu } from "./HamburgerMenu";
import { useNavigationContext } from "../context/navigationContext";
import { appConfig } from "../App";
export const AppHeader = () => {
  const [navState, updateNav] = useNavigationContext("sideBar");

  return (
    <header className="app-header">
      <nav className="navbar navbar-expand-lg static-top nav-bemo">
        <div className="container">
          <HamburgerMenu
            onClick={() => updateNav({ isOpen: !navState.isOpen })}
            isActive={navState.isOpen}
          />

          <a className="navbar-brand" href="/">
            <img src={logo} alt="" />
            <span>Developer</span>
          </a>

          <div className="collapse navbar-collapse">
            <ul
              className="nav navbar-nav ml-auto"
              role="menu"
              aria-label="Main"
            >
              <li className="nav-link ">
                <a href={appConfig().DeveloperPortalUrl}>HOME</a>
              </li>
              <li className="nav-link">
                <a href={`${appConfig().DeveloperPortalUrl}/docs/services/`}>
                  APIS
                </a>
              </li>
              <li className="nav-link">
                <a href={`${appConfig().DeveloperPortalUrl}/products`}>
                  PACKAGES
                </a>
              </li>
              <li className="nav-link">
                <a
                  href={`${appConfig().EnrollmentPortalUrl}/developer/sandbox`}
                >
                  SANDBOX
                </a>
              </li>
              <li className="nav-link">
                <a href="/">DOCUMENTATION</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
