import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { AppHeader } from "./components/AppHeader";
import { AppSidebar } from "./components/AppSidebar";
import "github-markdown-css";
import { CmsClient } from "./services/cmsClient";
import { BuildCmsRoutes } from "./components/BuildCmsRoutes";
import { useCmsDataContext } from "./context/cmsDataContext";
import { AppLoader } from "./components/AppLoader";
declare type AppConfig = {
  DeveloperPortalUrl: string;
  EnrollmentPortalUrl: string;
  CmsServerUri: string;
  SourceFileHost: string;
};

export const appConfig = (): AppConfig => {
  return (window as any).appConfig || {};
};
function App() {
  const client = new CmsClient();
  const [loaded, setLoaded] = useState(false);
  const [retryCount, updateRetryCount] = useState(0);
  const [, updateCmsStore] = useCmsDataContext("menuItemList");

  useEffect(() => {
    (async function () {
      try {
        const resp = await client.GetDocumentationGraph();
        updateCmsStore(resp.menu[0].menuItemsList.menuItems);
        setLoaded(true);
      } catch {
        //linear retry policy based on react livecycle
        setTimeout(() => updateRetryCount(retryCount + 1), retryCount * 250);
      }
    })();
  }, [retryCount]);

  return (
    <div className="App">
      {loaded ? (
        <Router>
          <AppHeader></AppHeader>
          <div className="App-spliter App-spliter-right">
            <AppSidebar></AppSidebar>
            <div className="container-fluid">
              <BuildCmsRoutes />
            </div>
          </div>
        </Router>
      ) : (
        <AppLoader></AppLoader>
      )}
    </div>
  );
}

export default App;
