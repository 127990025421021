import { createGlobalState } from "react-hooks-global-state";
import { MenuItem } from "../bemodocs-graphql-response.json";

type State = {
  menuItemList: MenuItem[];
};
let initialState: State = { menuItemList: [] };
const { useGlobalState } = createGlobalState(initialState);

export const useCmsDataContext = useGlobalState;
