import React, { useState } from "react";
import logo from "./logo.svg";
import { AppHeader } from "./AppHeader";
import "./AppSidebar.css";
import {
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigationContext } from "../context/navigationContext";
import { useCmsDataContext } from "../context/cmsDataContext";
export const AppSidebar = () => {
  const [cmsStore] = useCmsDataContext("menuItemList");
  const [sideBarState, updateSideBarState] = useNavigationContext("sideBar");
  const [navState] = useNavigationContext("navigation");
  function hideSideBar() {
    updateSideBarState({ isOpen: false });
  }
  const setActivePage = (name: string) =>
    navState.currentPage === name ? "nav-item-active" : "";

  return (
    <nav
      className={`app-sidebar App-spliter App-spliter-left ${
        !sideBarState.isOpen ? "" : "active"
      } `}
    >
      <div className="app-sidebar-content">
        <div className="app-sidebar-menu">
          {cmsStore.map((menu, i) => (
            <Nav vertical key={i}>
              <NavItem
                onClick={hideSideBar}
                className={setActivePage(menu.linkMenuItem.name)}
              >
                <Link to={{ pathname: menu.linkMenuItem.url }}>
                  {menu.linkMenuItem.name}
                </Link>
              </NavItem>
              {menu.menuItemsList?.menuItems.map((submenu, j) => (
                <NavItem
                  key={j}
                  onClick={hideSideBar}
                  className={`nav-item-sub ${setActivePage(
                    menu.linkMenuItem.name
                  )}`}
                >
                  <Link to={{ pathname: submenu.linkMenuItem.url }}>
                    {submenu.linkMenuItem.name}
                  </Link>
                </NavItem>
              ))}
            </Nav>
          ))}
        </div>
      </div>
    </nav>
  );
};
